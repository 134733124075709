<template>
	<div v-if="store.state === 2 && !store.livestreaming" id="visualizer" :style="`background-image: url(${store.tunecast.chat_artwork_image ? store.tunecast.chat_artwork_image :  store.playlistTracks[0].artwork});`"></div>
</template>
<script setup>
import { useStore } from '@/store';
const store = useStore();
</script>
<style type="text/css" scoped>


#visualizer {
	position: fixed;
	height: 400px;
	width: 400px;
	/*background-image: url(/lana.jpeg);*/
	background-size: cover;
	top: 50%;
	left: 50%;
	z-index: 5000;
	margin-top: -240px; 
	margin-left: -140px;
	z-index: 5000;
}

@media screen and (max-width: 1400px) {
	#visualizer {
		height: 370px;
		width: 370px;
		margin-top: -220px;
		margin-left: -120px;
	}
}

@media screen and (max-width: 1340px) {
	#visualizer {
		height: 340px;
		width: 340px;
		margin-left: -110px;
		margin-top: -200px;
	}
}

@media screen and (max-width: 1300px) {
	#visualizer {
		display: none;
	}
}
</style>