export default {
    'en': {
        'ADDMESSAGE': 'Add Message',
        'SEND': 'Send',

        'LOGINWSPOTIFY': 'Login with Spotify',
        'LOGINWAPPLE': 'Login with Apple Music',

        
        'LOGIN': 'Log In',
        'LOGINW': 'Login with',

        'USERNAME': 'Username',
        'EMAIL': 'Email',
        'PHONE': 'Phone',

        'RECEIVEOFFERS': 'SIGN UP TO RECEIVE EMAIL UPDATES AND OFFERS FROM:',
        'EMAILSSENTBY': 'Emails will be sent by or on behalf of Universal Music Group 2220 Colorado Avenue, Santa Monica , CA 90404 (310) 865-4000. You may withdraw your consent at any time. Privacy Policy / Do Not Sell My Personal Information',
        'SUBMIT': 'Submit',
        'ONLYALPHANUMERIC': 'Only alphanumeric characters allowed.',
        'USERNAMELENGTH': 'Username must be longer than 4 characters.',

        // content
        'PLAYLIST': 'Playlist',
        'MERCH': 'Merch',
        'SONGS': 'Songs',
        'NOWPLAYING': 'NOW PLAYING',
        'ONAIR': 'On Air',
        'FILTERMESSAGES': 'Filter Host Messages',
        'FAQHELP': 'FAQ/HELP',

        'FAQTROUBLE': 'If you are having trouble with audio follow these instructions…',
        'FAQAPPLE': '<p>For <b>Apple Music</b> users, try refreshing your browser and logging back into the listening experience.</p><br /><p style="margin-top: .5rem">Note* You must have an Apple Music account.</p>',
        'FAQSPOTIFY': 'For <b>Spotify free</b> users, upon entering the listening experience, you will need to follow the pop up instructions to get redirected to Spotify. If you missed it, please refresh your browser and log back in. - From there, click shuffle to begin the audio playback.</p><br /><p>For <b>Spotify Premium</b> users, please make sure you’re logging into the Tunecast with the same account used on your Spotify app.</p>',


        // free modal
        'FREEMODALTITLE': 'Spotify Free Users',
        'FREEMODALTEXT': '<p>Due to limitations with Spotify Free accounts, while you will not be able to sync your listening, but you can shuffle and join the chat! Click below to open the playlist on Spotify, and then press play to begin playback.</p><br /><p>Once you have started playing the music on Spotify, return to the chat!</p>',
        'FREEMODALBTN': 'Open Playlist on Spotify'
    },
    'jp': {
        // landing
        'LOGINWSPOTIFY': 'Spotifyでログイン',
        'LOGINWAPPLE': 'Apple Musicでログイン',

        'LOGIN': 'ログイン',
        'LOGINW': 'でログイン',

        // login modal
        'USERNAME': 'ユーザー名',
        'EMAIL': 'メールアドレス',
        'PHONE': '電話番号',

        'RECEIVEOFFERS': '次のトピックスに関する情報配信を希望する場合はチェックを入れてください',
        'EMAILSSENTBY': '情報配信を希望する場合、チェックを入れて「次へ」ボタンを押してご登録いただくことで、お客様は、当社が別途定めるニュースレター会員規約と個人情報保護方針に同意の上、ご登録されたことになります。',
        'SUBMIT': '次へ',

        'ONLYALPHANUMERIC': '英数字を入力ください',
        'USERNAMELENGTH': '4文字以上を入力してください',

        // content
        'PLAYLIST': 'プレイリスト',
        'SONGS': '曲',
        'MERCH': 'グッズ',
        'NOWPLAYING': '再生中',
        'ONAIR': '再生中',
        'FILTERMESSAGES': '主催側のメッセージのみ表示',
        'FAQHELP': 'FAQ/ヘルプ',
        'ADDMESSAGE': 'メッセージを書く',
        'SEND': '送信',

        'FAQTROUBLE': '再生に不具合がある場合は下記をご参照ください。',
        'FAQAPPLE': '<p><b>Apple Music</b>をご利用の場合、ブラウザを更新して再度ログインをお試しください。</p><br /><p>※Apple Musicのアカウントが必要です。</p>',
        'FAQSPOTIFY': '<p><b>Spotify</b>無料アカウントをご利用の場合、ログインの際に表示される各種手順に沿ってSpotifyアプリを開く必要があります。再度手順をご確認いただくには、ブラウザを更新して再度ログインをお試しください。手順に沿ってSpotifyアプリを開いたら、アプリ側で楽曲を再生した上で、ブラウザに戻ってチャットをお楽しみください。</p><br/><p><b>Spotify</b>プレミアムアカウントをご利用の場合、Spotifyアプリでログイン済みのアカウントと同じアカウントでログインしてください。</p>',

        // free modal
        'FREEMODALTITLE': 'Spotify無料アカウントご利用の方へ',
        'FREEMODALTEXT': '<p>Spotify無料アカウントの仕様により、他の参加者と再生を同期させることができないず楽曲はシャッフル再生で流れますが、当サイトにてチャットに参加頂くことは可能です。楽曲を再生するには、下記「Spotifyを開いてプレイリストを聴く」をクリックし、Spotifyアプリ側で楽曲を再生してください。</p><p>アプリ側で再生が始まったら、こちらのサイトに戻ってチャットをお楽しみください！</p>',
        'FREEMODALBTN': 'Spotifyを開いてプレイリストを聴く'
    },

    "fr": {
        "ADDMESSAGE": "Ajouter un message",
        "SEND": "Envoyer",

        "LOGIN": "Connexion",

        "LOGINWSPOTIFY": "Se connecter avec Spotify",
        "LOGINWAPPLE": "Se connecter avec Apple Music",

        "LOGINW": "Se connecter avec",

        "USERNAME": "Nom d'utilisateur",
        "EMAIL": "Email",
        "PHONE": "Téléphone",

        "RECEIVEOFFERS": "INSCRIVEZ-VOUS POUR RECEVOIR LES NEWSLETTERS DE LA PART DE :",
        "EMAILSSENTBY": "Les e-mails seront envoyés par ou pour le compte de Universal Music Group, 2220 Colorado Avenue, Santa Monica, CA 90404 (310) 865-4000. Vous pouvez retirer votre consentement à tout moment. Politique de confidentialité / Ne pas vendre mes informations personnelles",
        "SUBMIT": "Soumettre",
        "ONLYALPHANUMERIC": "Seuls les caractères alphanumériques sont autorisés.",
        "USERNAMELENGTH": "Le nom d'utilisateur doit contenir plus de 4 caractères.",

        "PLAYLIST": "Playlist",
        "MERCH": "Merchandising",
        "SONGS": "Titres",
        "NOWPLAYING": "EN LECTURE",
        "ONAIR": "À l'antenne",
        "FILTERMESSAGES": "Filtrer les messages de l'animateur",
        "FAQHELP": "FAQ/AIDE",

        "FAQTROUBLE": "Si vous rencontrez des problèmes avec l'audio, suivez ces instructions...",
        "FAQAPPLE": "<p>Pour les utilisateurs de <b>Apple Music</b>, essayez de rafraîchir votre navigateur et reconnectez-vous.</p><br /><p style=\"margin-top: .5rem\">Remarque* Vous devez avoir un compte Apple Music.</p>",
        "FAQSPOTIFY": "Pour les utilisateurs de <b>Spotify Free</b>, lors de l'entrée dans l'expérience d'écoute, vous devrez suivre les instructions de la pop-up pour être redirigé vers Spotify. Si vous l'avez manqué, veuillez rafraîchir votre navigateur et vous reconnecter. - De là, cliquez sur aléatoire pour commencer la lecture audio.</p><br /><p>Pour les utilisateurs de <b>Spotify Premium</b>, veuillez vous assurer que vous vous connectez au Tunecast avec le même compte utilisé sur votre application Spotify.</p>",

        "FREEMODALTITLE": "Utilisateurs de Spotify Free",
        "FREEMODALTEXT": "<p>En raison des limitations des comptes Spotify Free, bien que vous ne puissiez pas synchroniser votre écoute, vous pouvez tout de même faire un shuffle et rejoindre le chat ! Cliquez ci-dessous pour ouvrir la playlist sur Spotify, puis appuyez sur play pour commencer la lecture.</p><br /><p>Une fois que vous avez commencé à jouer la musique sur Spotify, revenez au chat !</p>",
        "FREEMODALBTN": "Ouvrir la playlist sur Spotify"
    },
    "es": {
        "ADDMESSAGE": "Agregar mensaje",
        "SEND": "Enviar",

        'LOGIN': 'Acceso',
        "LOGINWSPOTIFY": "Iniciar sesión con Spotify",
        "LOGINWAPPLE": "Iniciar sesión con Apple Music",

        "LOGINW": "Iniciar sesión con",

        "USERNAME": "Nombre de usuario",
        "EMAIL": "Correo electrónico",
        "PHONE": "Teléfono",

        "RECEIVEOFFERS": "REGÍSTRATE PARA RECIBIR ACTUALIZACIONES Y OFERTAS POR CORREO ELECTRÓNICO DE:",
        "EMAILSSENTBY": "Los correos electrónicos serán enviados por o en nombre de Universal Music Group 2220 Colorado Avenue, Santa Monica, CA 90404 (310) 865-4000. Puedes retirar tu consentimiento en cualquier momento. Política de privacidad / No vender mi información personal",
        "SUBMIT": "Enviar",
        "ONLYALPHANUMERIC": "Solo se permiten caracteres alfanuméricos.",
        "USERNAMELENGTH": "El nombre de usuario debe ser más largo de 4 caracteres.",

        "PLAYLIST": "Lista de reproducción",
        "MERCH": "Mercancía",
        "SONGS": "Canciones",
        "NOWPLAYING": "REPRODUCIENDO AHORA",
        "ONAIR": "En el aire",
        "FILTERMESSAGES": "Filtrar mensajes del anfitrión",
        "FAQHELP": "FAQ/AYUDA",

        "FAQTROUBLE": "Si tienes problemas con el audio, sigue estas instrucciones...",
        "FAQAPPLE": "<p>Para usuarios de <b>Apple Music</b>, intenta actualizar tu navegador y volver a iniciar sesión en la experiencia de escucha.</p><br /><p style=\"margin-top: .5rem\">Nota* Debes tener una cuenta de Apple Music.</p>",
        "FAQSPOTIFY": "Para usuarios de <b>Spotify Free</b>, al ingresar a la experiencia de escucha, necesitarás seguir las instrucciones emergentes para redirigirte a Spotify. Si te lo perdiste, por favor, actualiza tu navegador y vuelve a iniciar sesión. - Desde allí, haz clic en aleatorio para comenzar la reproducción de audio.</p><br /><p>Para usuarios de <b>Spotify Premium</b>, asegúrate de iniciar sesión en el Tunecast con la misma cuenta utilizada en tu aplicación de Spotify.</p>",

        "FREEMODALTITLE": "Usuarios de Spotify Gratis",
        "FREEMODALTEXT": "<p>Debido a las limitaciones con las cuentas de Spotify Gratis, mientras no podrás sincronizar tu escucha, puedes hacer shuffle y unirte al chat! Haz clic abajo para abrir la lista de reproducción en Spotify, y luego presiona play para comenzar la reproducción.</p><br /><p>Una vez que hayas empezado a reproducir la música en Spotify, ¡vuelve al chat!</p>",
        "FREEMODALBTN": "Abrir lista de reproducción en Spotify"
    }
}