<template>
	<div class="wrapper" :style="store.isMobile && store.windowHeight && `height: ${store.windowHeight}px`">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

					<h2>{{ LOCALE[store.tunecast.lang]['LOGIN'] }}</h2>

					<div style="padding: .5rem"></div>
					<input class="form-control" :placeholder="LOCALE[store.tunecast.lang]['USERNAME'] + ' *'" v-model="username">
					<p v-if="error" style="color: red; text-align: left; font-weight: 900; font-size: 13px">{{error}}</p>
					<div v-if="store.tunecast.optin_email_enabled" style="padding: .25rem"></div>
					<input v-if="store.tunecast.optin_email_enabled" class="form-control" :placeholder="LOCALE[store.tunecast.lang]['EMAIL']" v-model="email">

					<div v-if="store.tunecast.optin_mobile_enabled" style="padding: .25rem"></div>
					<input v-if="store.tunecast.optin_mobile_enabled" class="form-control" :placeholder="LOCALE[store.tunecast.lang]['PHONE']" v-model="phone">

					<div v-if="store.tunecast.optin_lists" style="padding: .25rem"></div>
					<select v-if="store.tunecast.optin_lists" name="" id="" class="form-control" v-model="countryCode">
						<option :value="code" v-for="code in Object.keys(countries)" :key="code">{{ countries[code] }}</option>
					</select>

					<p v-if="store.tunecast.optin_lists" style="color: #9A9A9A; padding-top: .45rem; cursor: pointer; font-size: 14px; text-align: left;">{{ LOCALE[store.tunecast.lang]['RECEIVEOFFERS'] }}</p>

					<div v-if="store.tunecast.optin_lists">
						<div 
							v-for="(list,key) in store.tunecast.optin_lists.split(',')" 
							:key="key"
							style="margin-top: .15rem; text-align: left; align-items: center; display: flex; justify-content: left;"
						>
							<input 
								v-model="optins[list]" 
								type="checkbox" 
								:id="list.toLowerCase().split(' ').join('-')" 
								style="cursor: pointer;"
							>
							<label :for="list.toLowerCase().split(' ').join('-')" style="color: #000; font-weight: 700; padding-left: .25rem; cursor: pointer; font-size: 14px; text-align: left;">{{list}}</label>
						</div>
					</div>

					<br />
					<p style="text-align: left; color: #9A9A9A; font-size: 8px" v-if="store.tunecast.optin_lists">
						<span v-if="store.tunecast.optin_text"><span v-html="replaceURLsWithLinks(store.tunecast.optin_text)"></span>"</span>
						<span v-else>{{ LOCALE[store.tunecast.lang]['EMAILSSENTBY'] }}</span>
					</p>
					<br />
					<button class="btn" @click="setUsername">Submit</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';
import { useStore } from '@/store';
import { BACKEND_URL } from '@/services/config';
import LOCALE from '@/services/locale';

const countryCode = ref('US');
const store = useStore();

const username = ref('');
const email = ref('');
const phone = ref('');
const error = ref('');
const optins = ref({});

onMounted(async () => {
	if(!store.tunecast.optin_lists) return;
	store.tunecast.optin_lists.split(',').forEach(o => optins.value[o] = false);

	let ipData = await (await fetch(`${BACKEND_URL}/ip-locale`)).json();
  if(ipData && ipData.country_code) countryCode.value = ipData.country_code;

});

const props = defineProps(['submit'])
const emit = defineEmits(['close']);
let regexp = /^[a-zA-Z0-9\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]*$/;

const resetValues = () => {
	username.value = '';
	email.value = '';
	phone.value = '';
	error.value = '';
}
const close = () => {
	resetValues()
	emit('close')
};

function replaceURLsWithLinks(str) {
    // Regular expression to match URLs
    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;

    // Replace URLs with anchor tags
    return str.replace(urlRegex, function(url) {
        return '<a target="_blank" href="' + url + '">' + url + '</a>';
    });
}

function setUsername() {
	error.value = '';
	console.log('testing...', username.value, regexp.test(username.value))
	if(!regexp.test(username.value))
		return error.value = LOCALE[store.tunecast.lang]['ONLYALPHANUMERIC'];
	if(username.value.length < 4)
		return error.value = LOCALE[store.tunecast.lang]['USERNAMELENGTH']; // 'Username must be longer than 4 characters.'

	let _optins = Object.keys(optins.value).filter(k => optins.value[k]);
	fetch(`${BACKEND_URL}/optin`, {
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({ 
			email: email.value, 
			phone: phone.value,
			name: username.value,
			ns: store.tunecast.subdomain,
			optins: _optins,
			country: countryCode.value
		}),
    });

	props.submit({
		username: username.value,
		email: email.value
	})

	resetValues()
}


var countries = {
  "AF": "Afghanistan",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BR": "Brazil",
  "BN": "Brunei",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "CV": "Cabo Verde",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo (DRC)",
  "CR": "Costa Rica",
  "CI": "Côte d'Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czechia",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "SZ": "Eswatini",
  "ET": "Ethiopia",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GR": "Greece",
  "GD": "Grenada",
  "GT": "Guatemala",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HN": "Honduras",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Korea (North)",
  "KR": "Korea (South)",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Laos",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "MX": "Mexico",
  "FM": "Micronesia",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestine",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PL": "Poland",
  "PT": "Portugal",
  "QA": "Qatar",
  "RO": "Romania",
  "RU": "Russia",
  "RW": "Rwanda",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "São Tomé and Príncipe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "SS": "South Sudan",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syria",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VA": "Vatican City",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
};

</script>

<style type="text/css" scoped>
.x-wrap {
	position: absolute;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 5;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 22rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.form-control {
	background-color: #EBEBEB;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: .85rem;
	color: #000;
	border-radius: 5px;
	outline: none !important;
}
</style>