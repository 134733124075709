const currencies = {
    dollar: ["USD", "MXN", "CAD", "AUD", "NZD", "SGD", "HKD", "TTD", "XCD", "BMD", "KYD"],
    euro: ["EUR"],
    pound: ["GBP", "FKP", "GIP", "SHP"],
    yen: ["JPY"],
    franc: ["CHF", "XAF", "XOF", "XPF", "CDF"],
    rupee: ["INR", "PKR", "LKR", "SCR", "MUR", "NPR"],
    rand: ["ZAR", "SZL", "LSL", "NAD"],
    yuan: ["CNY"],
    peso: ["ARS", "CLP", "COP", "DOP", "UYU"],
    real: ["BRL"],
    krona: ["SEK", "NOK", "DKK", "ISK"],
    other: [
      "AED", "AFN", "ALL", "AMD", "ANG", "AOA", "AWG", "AZN", "BAM", "BBD", "BDT", "BGN", "BIF",
      "BND", "BOB", "BSD", "BWP", "BZD", "CDF", "CZK", "DJF", "DZD", "EGP", "ETB", "FJD", "GEL",
      "GNF", "GTQ", "GYD", "HNL", "HTG", "HUF", "IDR", "ILS", "KES", "KHR", "KMF", "KRW", "KZT",
      "LBP", "LRD", "MGA", "MKD", "MMK", "MNT", "MOP", "MVR", "MWK", "MZN", "NGN", "PEN", "PGK",
      "PHP", "PLN", "PYG", "QAR", "RON", "RSD", "RUB", "RWF", "SAR", "SBD", "SLL", "SRD", "STD",
      "SZL", "TJS", "TOP", "TRY", "TWD", "TZS", "UAH", "UGX", "UZS", "VND", "VUV", "WST", "XPF",
      "YER", "ZMW",
    ],
  };

export function renderAmountInCurrency(price) {
    let { currencyCode } = price;
    let str = '';
    if (currencies.dollar.includes(currencyCode)) {
      str += "$";
    } else if (currencies.euro.includes(currencyCode)) {
      str += "€";
    } else if (currencies.pound.includes(currencyCode)) {
      str += "£";
    } else if (currencies.yen.includes(currencyCode)) {
      str += "¥";
    } else if (currencies.franc.includes(currencyCode)) {
      str += "Fr";
    } else if (currencies.rupee.includes(currencyCode)) {
      str += "₹";
    } else if (currencies.rand.includes(currencyCode)) {
      str += "R";
    } else if (currencies.yuan.includes(currencyCode)) {
      str += "¥"; // Same as Yen
    } else if (currencies.peso.includes(currencyCode)) {
      str += "$"; // Peso uses Dollar symbol
    } else if (currencies.real.includes(currencyCode)) {
      str += "R$";
    } else if (currencies.krona.includes(currencyCode)) {
      str += "kr";
    } else {
      str += "¤"; // Generic currency symbol for undefined
    }

    str += price.amount + ' ' + currencyCode;
    return str;
}  