<template>
	<div class="dark-overlay"></div>
	<div class="wrapper">
		<div class="content">
			<div class="card">
				<div class="card-body">
					<div class="x-wrap" @click="close">
						<img src="/svgs/x-vector.svg">
					</div>

					<img style="height: 10rem" :src="product.image">

					<h3>{{props.product.product_title}}</h3>
					<h3>{{ renderAmountInCurrency(props.product.variants[0].price) }} </h3>
					<br />
					<div>
						<button class="btn" @click="addToCart">Add to Cart</button>
					</div>
					<br />
					<div v-html="props.product.description"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { renderAmountInCurrency } from '../../../services/currenyService';
import { defineEmits, defineProps } from 'vue';
const emit = defineEmits(['close', 'select']);
const props = defineProps(['product']);

// console.log(renderAmountInCurrency);

const close = () => emit('close');
const addToCart = () => emit('select');
// const _renderAmountInCurrency = value => 10;

</script>

<style type="text/css" scoped>
.dark-overlay {
	position: fixed;
	z-index: 40000;
	height: 120%;
	width: 100vw;
	top: 0;
	background-color: rgba(0,0,0,.4);
}

.x-wrap {
	position: absolute;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100%;
	top: 0;
	width: 100vw;
	z-index: 50000;
	backdrop-filter: blur(10px);
}

.content {
	text-align: center;
	width: 100%;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	/*width: 28rem;*/
	width: 90%;
	border-radius: 20px;
	margin: auto;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.card {
	overflow: scroll;
	max-height: 90vh;
}

@media screen and (min-width: 750px) {
	.card {
		max-width: 600px;
		max-height: 500px;
	}
}

</style>